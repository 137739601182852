import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPad/Install/IN-9010-9020/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer iPhone oder iPad App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_iOS.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_iOS.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "ipad-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ipad-app-installationsassistent",
        "aria-label": "ipad app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPad App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera-in-9010-fhd--in-9020-fhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera-in-9010-fhd--in-9020-fhd",
        "aria-label": "hinzufügen einer neuen ip kamera in 9010 fhd  in 9020 fhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera (`}<em parentName="h3">{`IN-9010 FHD & IN-9020 FHD`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/49853/iPad_Wizard_Installation_9020_9010_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVQoz5WSbW/bMAyE/f//Zlagmy0rJCW+ibKGZIubOknRHp5PFA6idDf9Xs7zkn6dTiklVS1Vc+3mvbUL7hGxIRbEErH9G0ZsherpPU9nJGZWVWZuEaKe0Uq1eoPZAQpAZfZ9KGyZ2rSuKaU0booWhALwCURF1M9DRuAJAEopY4ztqtYCUb7JlHMmojFG7/2pmUh3nphTSttN7bp2IaHrMQAvC8xznufzsgAA3zNFhLvv5t77gvqedQFVNfdWq5TCpXCt7B47ojGNB2G1TFqqtObjtVqMaTuq52IziIows4i8MvujOSIuSxIjXiDiepf5PaXa0fz424fM7zm+OaL/IOcvbr7U6DvmvWE9ot5CPhSD6KM2/81mJiIppVq5uf0Be1s9kbu3dT3nDCJq5gBEVM1cRBGLiJn5Xz4nZE4YBR+WAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/e4706/iPad_Wizard_Installation_9020_9010_01.avif 230w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/d1af7/iPad_Wizard_Installation_9020_9010_01.avif 460w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/7f308/iPad_Wizard_Installation_9020_9010_01.avif 920w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/e1c99/iPad_Wizard_Installation_9020_9010_01.avif 1380w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/76ea5/iPad_Wizard_Installation_9020_9010_01.avif 1840w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/b105c/iPad_Wizard_Installation_9020_9010_01.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/a0b58/iPad_Wizard_Installation_9020_9010_01.webp 230w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/bc10c/iPad_Wizard_Installation_9020_9010_01.webp 460w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/966d8/iPad_Wizard_Installation_9020_9010_01.webp 920w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/445df/iPad_Wizard_Installation_9020_9010_01.webp 1380w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/78de1/iPad_Wizard_Installation_9020_9010_01.webp 1840w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/03b06/iPad_Wizard_Installation_9020_9010_01.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/81c8e/iPad_Wizard_Installation_9020_9010_01.png 230w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/08a84/iPad_Wizard_Installation_9020_9010_01.png 460w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/c0255/iPad_Wizard_Installation_9020_9010_01.png 920w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/b1001/iPad_Wizard_Installation_9020_9010_01.png 1380w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/161ec/iPad_Wizard_Installation_9020_9010_01.png 1840w", "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/49853/iPad_Wizard_Installation_9020_9010_01.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/92aff80a066fb6ec08f8fdc45beb9dcd/c0255/iPad_Wizard_Installation_9020_9010_01.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Menü `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie den `}<strong parentName="li">{`Installationsassistenten`}</strong>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e20135fa56892f75dfad453fc952530f/49853/iPad_Wizard_Installation_9020_9010_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVQoz32QW2vbQBCF9cfbl2AKJeSHtNA+B2yaPrUPvefBlKSWJVmxbqu97+zMrqwiOzYxND0chuHsfszsJnUn2o5tioIx5r03znM7gI9Ek0MYrHV13bRtZy2EMBCFEAatTV71iVDaOee9d87FGMGTMAQQEaP3ATEqZeu6a5pOKYM4eB88RsJB2pj0PWOMjUfFOHQtz/NtUWyz7KEoqtWqSNNNmk61KKqiqLKszPNt2/BESmmMGcdxGHa73S6EWJZ1mm6yrEzTzXpd5vnDcnm/XN6t149Jmhbr9aYsm4RzrrXew8MBNgatpZOdC1p7Y9C58DQ3BieYMbY7KoSoFJwspRPCSOn63rSteJorBUmMkYiegT3v1SrvNtv+9tfy0+cvXSu09qcLyXiuGIezycKWFa/q/vdd9vX2T9fyM3h3rtNkIaGXwCW0neq5ZdxWjRTKczkd/ReWENGN8WAYg5sc4ZBE756FpQJr4M03fHEdZotwMQ+vFpMv5jRbhJfX4e13dAbkP988wRre/cDZnC5v6PUHuvpIV/vm8oZmc3r/8wg/t3bw+1XpWA/N3gHO1x7H8dAMMVoNWoEQIKQX0nMB/LyX6vHDE0QEAMaYtS4QNhLLnpgmotD3gnMJ4BFJSq21RSQAr5QBQET6CySHTvpE60UtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e20135fa56892f75dfad453fc952530f/e4706/iPad_Wizard_Installation_9020_9010_02.avif 230w", "/en/static/e20135fa56892f75dfad453fc952530f/d1af7/iPad_Wizard_Installation_9020_9010_02.avif 460w", "/en/static/e20135fa56892f75dfad453fc952530f/7f308/iPad_Wizard_Installation_9020_9010_02.avif 920w", "/en/static/e20135fa56892f75dfad453fc952530f/e1c99/iPad_Wizard_Installation_9020_9010_02.avif 1380w", "/en/static/e20135fa56892f75dfad453fc952530f/76ea5/iPad_Wizard_Installation_9020_9010_02.avif 1840w", "/en/static/e20135fa56892f75dfad453fc952530f/b105c/iPad_Wizard_Installation_9020_9010_02.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e20135fa56892f75dfad453fc952530f/a0b58/iPad_Wizard_Installation_9020_9010_02.webp 230w", "/en/static/e20135fa56892f75dfad453fc952530f/bc10c/iPad_Wizard_Installation_9020_9010_02.webp 460w", "/en/static/e20135fa56892f75dfad453fc952530f/966d8/iPad_Wizard_Installation_9020_9010_02.webp 920w", "/en/static/e20135fa56892f75dfad453fc952530f/445df/iPad_Wizard_Installation_9020_9010_02.webp 1380w", "/en/static/e20135fa56892f75dfad453fc952530f/78de1/iPad_Wizard_Installation_9020_9010_02.webp 1840w", "/en/static/e20135fa56892f75dfad453fc952530f/03b06/iPad_Wizard_Installation_9020_9010_02.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e20135fa56892f75dfad453fc952530f/81c8e/iPad_Wizard_Installation_9020_9010_02.png 230w", "/en/static/e20135fa56892f75dfad453fc952530f/08a84/iPad_Wizard_Installation_9020_9010_02.png 460w", "/en/static/e20135fa56892f75dfad453fc952530f/c0255/iPad_Wizard_Installation_9020_9010_02.png 920w", "/en/static/e20135fa56892f75dfad453fc952530f/b1001/iPad_Wizard_Installation_9020_9010_02.png 1380w", "/en/static/e20135fa56892f75dfad453fc952530f/161ec/iPad_Wizard_Installation_9020_9010_02.png 1840w", "/en/static/e20135fa56892f75dfad453fc952530f/49853/iPad_Wizard_Installation_9020_9010_02.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e20135fa56892f75dfad453fc952530f/c0255/iPad_Wizard_Installation_9020_9010_02.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie finden den `}<strong parentName="li">{`QR-Code`}</strong>{` in der `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Internet_Access/"
        }}>{`WebUI`}</a>{` (A), auf dem Etikett Ihrer Kamera (B) oder auf der Verpackung abgedruckt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/49853/iPad_Wizard_Installation_9020_9010_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAyklEQVQoz62S3WqEQAyFJ5k1Y36G0VQUvRF8/4csjHRxi4tu6XeZ5JCTcEJ4EBCHP4IR4iP8L3Dg1jQztz+4+zAMXxURedZjjGf2EVUVEWMFEQEAK88KEbVtey4+bxyIMaaUrsWI2FSOPq/FXBnHcV3XbdumaZLKLXHO2d1LKSKSczazvu/d/QPbH9/MzJfi8wUAYGbMbGYiwq/sLRFpmuZtThBxWRYi+mVqnueU0nXa9hgxcyml6zoz20+9ldMQAhGpqlVU9d0vvgHlHwsUrtTI2gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/e4706/iPad_Wizard_Installation_9020_9010_03.avif 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/d1af7/iPad_Wizard_Installation_9020_9010_03.avif 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/7f308/iPad_Wizard_Installation_9020_9010_03.avif 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/e1c99/iPad_Wizard_Installation_9020_9010_03.avif 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/76ea5/iPad_Wizard_Installation_9020_9010_03.avif 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/b105c/iPad_Wizard_Installation_9020_9010_03.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/a0b58/iPad_Wizard_Installation_9020_9010_03.webp 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/bc10c/iPad_Wizard_Installation_9020_9010_03.webp 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/966d8/iPad_Wizard_Installation_9020_9010_03.webp 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/445df/iPad_Wizard_Installation_9020_9010_03.webp 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/78de1/iPad_Wizard_Installation_9020_9010_03.webp 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/03b06/iPad_Wizard_Installation_9020_9010_03.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/81c8e/iPad_Wizard_Installation_9020_9010_03.png 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/08a84/iPad_Wizard_Installation_9020_9010_03.png 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/c0255/iPad_Wizard_Installation_9020_9010_03.png 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/b1001/iPad_Wizard_Installation_9020_9010_03.png 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/161ec/iPad_Wizard_Installation_9020_9010_03.png 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/49853/iPad_Wizard_Installation_9020_9010_03.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/c0255/iPad_Wizard_Installation_9020_9010_03.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/49853/iPad_Wizard_Installation_9020_9010_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBklEQVQoz52TYW8DIQiG/f+/csn2pVdB1KuIgrfkTG/Nmuy6Pt94w0sA0V08XT18fX4CQK013xiyXJP4VAszcxERAEBEEeGdWisRLcviKOVSSq21lNK7VukLRMqFm7XWe++qOj2q2u+oqpk5RACA7c4YA/x1zWn7k7HjYozruh6xmeGOmY0zXAgh57xt28w2M+99COEod2IGgCM2sxBCSmm8gFPV1tqj5L1HxJfMz8tAxJROFjZxv4r9r+1nMxHFGN8xz5mJ6J2ZxxiISETvzDxseA/MfLz8eduPJ0EBK5epHPrjVf6c5/wrAHC7ld5kifKxrBdcpbWww8wiEmPMOc/klNIUvwHvCXHbfiAJ6AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed0849abc31e7ac2e8cdbb37836868d2/e4706/iPad_Wizard_Installation_9020_9010_04.avif 230w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/d1af7/iPad_Wizard_Installation_9020_9010_04.avif 460w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/7f308/iPad_Wizard_Installation_9020_9010_04.avif 920w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/e1c99/iPad_Wizard_Installation_9020_9010_04.avif 1380w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/76ea5/iPad_Wizard_Installation_9020_9010_04.avif 1840w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/b105c/iPad_Wizard_Installation_9020_9010_04.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed0849abc31e7ac2e8cdbb37836868d2/a0b58/iPad_Wizard_Installation_9020_9010_04.webp 230w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/bc10c/iPad_Wizard_Installation_9020_9010_04.webp 460w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/966d8/iPad_Wizard_Installation_9020_9010_04.webp 920w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/445df/iPad_Wizard_Installation_9020_9010_04.webp 1380w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/78de1/iPad_Wizard_Installation_9020_9010_04.webp 1840w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/03b06/iPad_Wizard_Installation_9020_9010_04.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed0849abc31e7ac2e8cdbb37836868d2/81c8e/iPad_Wizard_Installation_9020_9010_04.png 230w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/08a84/iPad_Wizard_Installation_9020_9010_04.png 460w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/c0255/iPad_Wizard_Installation_9020_9010_04.png 920w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/b1001/iPad_Wizard_Installation_9020_9010_04.png 1380w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/161ec/iPad_Wizard_Installation_9020_9010_04.png 1840w", "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/49853/iPad_Wizard_Installation_9020_9010_04.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed0849abc31e7ac2e8cdbb37836868d2/c0255/iPad_Wizard_Installation_9020_9010_04.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-9020FullHD`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/49853/iPad_Wizard_Installation_9020_9010_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABk0lEQVQoz4WSTW7cMAyF52jtskCA9lDtJbIoglyjvUQWRRYFAiROZ2zLki1R/JFE24XdzDSTmUEe3oIi8YGkwE3TDaazz1VlrRWRSOJwJBm1jKWMOavqBBBjRNWprEnVKQJWdb8ZAhCRiBBRUWXJLiSICfHFRNkYZ4wjyockU+qhbJyz1tp5Ly0Kgb0/snPgHBwnKXjaeO9jjPM8T6tK0RD4jQEEQE7zm77vAWCe53Ecz8LDgHXdmbZv2/4MbK2d9noDA0jXDc9VXdfddmuWaV/DqppzvgR7T8OASNnaYMxJ5/lYquN/MhCAPDw83d//3m7bMztPx3rd2XuKMVXV7u7ulzHD6Z9dhCMwxsWEHANSfHliXErvwHbgxnG7unHS2EO8lC7u7D1z5K8/8odrvbrRT9/1y61+vl2Cqxv9eK3ffmbB5VQudt51/NjwU7u4Wv0vfmx4Z4/HPlzYqIrAEBiBBUVQODJFZly9BhT3cEqJma21iFRyan3647KFnHNxbuh7zywpZe8BAFPKzBJCZE4p5b9CZVTHIMqYugAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/e4706/iPad_Wizard_Installation_9020_9010_05.avif 230w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/d1af7/iPad_Wizard_Installation_9020_9010_05.avif 460w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/7f308/iPad_Wizard_Installation_9020_9010_05.avif 920w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/e1c99/iPad_Wizard_Installation_9020_9010_05.avif 1380w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/76ea5/iPad_Wizard_Installation_9020_9010_05.avif 1840w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/b105c/iPad_Wizard_Installation_9020_9010_05.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/a0b58/iPad_Wizard_Installation_9020_9010_05.webp 230w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/bc10c/iPad_Wizard_Installation_9020_9010_05.webp 460w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/966d8/iPad_Wizard_Installation_9020_9010_05.webp 920w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/445df/iPad_Wizard_Installation_9020_9010_05.webp 1380w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/78de1/iPad_Wizard_Installation_9020_9010_05.webp 1840w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/03b06/iPad_Wizard_Installation_9020_9010_05.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/81c8e/iPad_Wizard_Installation_9020_9010_05.png 230w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/08a84/iPad_Wizard_Installation_9020_9010_05.png 460w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/c0255/iPad_Wizard_Installation_9020_9010_05.png 920w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/b1001/iPad_Wizard_Installation_9020_9010_05.png 1380w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/161ec/iPad_Wizard_Installation_9020_9010_05.png 1840w", "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/49853/iPad_Wizard_Installation_9020_9010_05.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d5d5562efe4af751fe9c6f3a4a8e7149/c0255/iPad_Wizard_Installation_9020_9010_05.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1e75ef0a4630687f783366c554928857/49853/iPad_Wizard_Installation_9020_9010_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABV0lEQVQoz5VS227rMAzL//9mN6BN5ji2bN1tD2mXoN1whh2CD4QgWjLE6faxLR/h7XIJITBzQY61kTS3ZtZU3b3nXHIu7t3uRfdeoF6ucdoyICIzI6K5E2vMAkVq/SKiplRSqoh6Fgklgk3rGkII44CbQ6aUXpgz58yvRcwJp5RSKWWM0e8w85zpj5xijAAwxmitPZsB+F+8L3KYQwj9gJmXvYOWZZvnMM/rssR5Xg/G2y0sSwSgAjS5u6qe5tZaRhM1EeE7noWZm1lrnrlfoU/jFb33ikxED8MpEFFEzjaxATym/gOxygosvNtUH4O/nnD3hxlkXOGHubVWkfdLkuC+wkPs+p4GFTEVK2hbse/m30/1dHmCTN//7N7+486/TN5j9BfzmbDmXmFf6czJ2QpwiiMkIkJEIYRa0VTmJO+rBlBVW9ctxkTEIpoSAFQRJeKcC5GI6CfJVmOW5fuHXwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1e75ef0a4630687f783366c554928857/e4706/iPad_Wizard_Installation_9020_9010_06.avif 230w", "/en/static/1e75ef0a4630687f783366c554928857/d1af7/iPad_Wizard_Installation_9020_9010_06.avif 460w", "/en/static/1e75ef0a4630687f783366c554928857/7f308/iPad_Wizard_Installation_9020_9010_06.avif 920w", "/en/static/1e75ef0a4630687f783366c554928857/e1c99/iPad_Wizard_Installation_9020_9010_06.avif 1380w", "/en/static/1e75ef0a4630687f783366c554928857/76ea5/iPad_Wizard_Installation_9020_9010_06.avif 1840w", "/en/static/1e75ef0a4630687f783366c554928857/b105c/iPad_Wizard_Installation_9020_9010_06.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1e75ef0a4630687f783366c554928857/a0b58/iPad_Wizard_Installation_9020_9010_06.webp 230w", "/en/static/1e75ef0a4630687f783366c554928857/bc10c/iPad_Wizard_Installation_9020_9010_06.webp 460w", "/en/static/1e75ef0a4630687f783366c554928857/966d8/iPad_Wizard_Installation_9020_9010_06.webp 920w", "/en/static/1e75ef0a4630687f783366c554928857/445df/iPad_Wizard_Installation_9020_9010_06.webp 1380w", "/en/static/1e75ef0a4630687f783366c554928857/78de1/iPad_Wizard_Installation_9020_9010_06.webp 1840w", "/en/static/1e75ef0a4630687f783366c554928857/03b06/iPad_Wizard_Installation_9020_9010_06.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1e75ef0a4630687f783366c554928857/81c8e/iPad_Wizard_Installation_9020_9010_06.png 230w", "/en/static/1e75ef0a4630687f783366c554928857/08a84/iPad_Wizard_Installation_9020_9010_06.png 460w", "/en/static/1e75ef0a4630687f783366c554928857/c0255/iPad_Wizard_Installation_9020_9010_06.png 920w", "/en/static/1e75ef0a4630687f783366c554928857/b1001/iPad_Wizard_Installation_9020_9010_06.png 1380w", "/en/static/1e75ef0a4630687f783366c554928857/161ec/iPad_Wizard_Installation_9020_9010_06.png 1840w", "/en/static/1e75ef0a4630687f783366c554928857/49853/iPad_Wizard_Installation_9020_9010_06.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1e75ef0a4630687f783366c554928857/c0255/iPad_Wizard_Installation_9020_9010_06.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Wählen Sie, ob Ihre Kamera über `}<strong parentName="li">{`WiFi`}</strong>{` angeschlossen werden soll oder ob Sie ein Ethernet-Kabel verwenden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7cd45d98dadbfbfae7fb08e638198029/49853/iPad_Wizard_Installation_9020_9010_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABuklEQVQoz4WSW44TMRBFszf4HAmJPSGxiPngsQ3EQmBIdyedDOl02S6X7fKjnEadTJgkKOLqfJRdOrJd8mI3mv0I/XoNADFGF6JyNUQpRYocRGqtE5Ej8rVOIrWUGSK3+q0WxlIIIcYYQhARjtlQDlyYM6JDdNZ6pRAAEb0xZK1nLikJelkoBQAwnSNSh0H1/W673bdt3zT9crleLtdPT91yue66bddtt9v9ZjMMO7VAROfcNE2HY0oRpWgcUSk6sduppunbth9H1NopRQAWwCpFC601EU3TVGs9yUTxEu+zD0VrhxhuWrMMAIdzShFr+RWK+71ZdRsAbW1ADJfdhYjknO/JxngNNO6NMc5em7M8XUekXra1dl3Td21vbbwxZ/lwnZuTieLPH7/apncu4b8n35MN8mgYDGtMCtOo5xpedu7LaJkdf/iW3zzKuy/y8Enef515+Dwv3z7Kx+85eka8/+bnkbtdXA0z6yOnuhvi8/i/awfH0Qf2HP18kZljHT17dy3//WFVxBPTeVpE8WJ4r1N8kVNKzAwA3oeS04BpozJQzrkoZbRG5phSRiQin1JmjtY65pRS/gPB4FJrPFqd2AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7cd45d98dadbfbfae7fb08e638198029/e4706/iPad_Wizard_Installation_9020_9010_07.avif 230w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/d1af7/iPad_Wizard_Installation_9020_9010_07.avif 460w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/7f308/iPad_Wizard_Installation_9020_9010_07.avif 920w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/e1c99/iPad_Wizard_Installation_9020_9010_07.avif 1380w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/76ea5/iPad_Wizard_Installation_9020_9010_07.avif 1840w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/b105c/iPad_Wizard_Installation_9020_9010_07.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7cd45d98dadbfbfae7fb08e638198029/a0b58/iPad_Wizard_Installation_9020_9010_07.webp 230w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/bc10c/iPad_Wizard_Installation_9020_9010_07.webp 460w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/966d8/iPad_Wizard_Installation_9020_9010_07.webp 920w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/445df/iPad_Wizard_Installation_9020_9010_07.webp 1380w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/78de1/iPad_Wizard_Installation_9020_9010_07.webp 1840w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/03b06/iPad_Wizard_Installation_9020_9010_07.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7cd45d98dadbfbfae7fb08e638198029/81c8e/iPad_Wizard_Installation_9020_9010_07.png 230w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/08a84/iPad_Wizard_Installation_9020_9010_07.png 460w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/c0255/iPad_Wizard_Installation_9020_9010_07.png 920w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/b1001/iPad_Wizard_Installation_9020_9010_07.png 1380w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/161ec/iPad_Wizard_Installation_9020_9010_07.png 1840w", "/en/static/7cd45d98dadbfbfae7fb08e638198029/49853/iPad_Wizard_Installation_9020_9010_07.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7cd45d98dadbfbfae7fb08e638198029/c0255/iPad_Wizard_Installation_9020_9010_07.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/49853/iPad_Wizard_Installation_9020_9010_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABt0lEQVQoz32S3WrcMBBG/aS5CxT6OqXPEEhKu32FFtq7kFy3ybLe7DrrHUmjkTUzkmwX7x/ZkuTjYEYzHNkyqp63rt3Csq4BQERCFNv1LCXnktLEMIxEgagbhjGlolpy7om6urGV8xRjFJEYYy6FJVmvXUzMWSR3nSAGAAfgEEMIIpKZs0q2lCtjAADGY0ou5LlZb1erdrVq67qZz5eLxXo+Xz0+LheLZr3ePj1tmga8jxXuNhzHcdgl5+I9G0PGeICJXU3GBmNo3wHw1pL3XFlriWgcx77vTzKRnAhBg2cy9LJJJAcZAIZj9vJLnjf2edngvPbE/42qUkpK6S3ZuW5ZNw8PC+OCcwExnsnjeUrp9wPECYex3WLbOsSIxybiUR7Os38zImvkXnbwGYNMI3xHDsS//sjNrc7u9Nudfr/X2f1UzO705lZ//5VAk/+KjJ5j4E8/0sVV+fClXF6Xj18nLq+n5cVV+fwzcZi+7s0zF+ZRX6fwu2ee/jOyddPzVc7k0w3rS+mIaTc7XQZ/WJ6KQ7NSVWYGgK6LOekG9ckkoJRSNsZZi8yimhCJqFNNzOJ9YFbV9A+pFlO5ROow/AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/e4706/iPad_Wizard_Installation_9020_9010_08.avif 230w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/d1af7/iPad_Wizard_Installation_9020_9010_08.avif 460w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/7f308/iPad_Wizard_Installation_9020_9010_08.avif 920w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/e1c99/iPad_Wizard_Installation_9020_9010_08.avif 1380w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/76ea5/iPad_Wizard_Installation_9020_9010_08.avif 1840w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/b105c/iPad_Wizard_Installation_9020_9010_08.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/a0b58/iPad_Wizard_Installation_9020_9010_08.webp 230w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/bc10c/iPad_Wizard_Installation_9020_9010_08.webp 460w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/966d8/iPad_Wizard_Installation_9020_9010_08.webp 920w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/445df/iPad_Wizard_Installation_9020_9010_08.webp 1380w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/78de1/iPad_Wizard_Installation_9020_9010_08.webp 1840w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/03b06/iPad_Wizard_Installation_9020_9010_08.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/81c8e/iPad_Wizard_Installation_9020_9010_08.png 230w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/08a84/iPad_Wizard_Installation_9020_9010_08.png 460w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/c0255/iPad_Wizard_Installation_9020_9010_08.png 920w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/b1001/iPad_Wizard_Installation_9020_9010_08.png 1380w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/161ec/iPad_Wizard_Installation_9020_9010_08.png 1840w", "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/49853/iPad_Wizard_Installation_9020_9010_08.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a76e33c0dc1ce2b0eead81b5a7e53fa4/c0255/iPad_Wizard_Installation_9020_9010_08.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/49853/iPad_Wizard_Installation_9020_9010_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBklEQVQoz31S227TQBD11/WV8tovQuIb+lIe4CsQj0CUuKnJ3U4au6aJnfV6d/Z+sVHixFAVODoanTmjo9VqJtgf6vKAnrIMIaSUYkJh7qX2znp7ovctAGdMeN92jnMNA57tcFBTEEIopYQQ1jmpDKYamOb8SMYUxrQoqqJAdU07k3MthcZgg6pCCKH2AmcdUEnImXUtDgeCEEWIlmXd+4QISkRACGGMtW3bnGCto1QCKABFqaRUcm7KEu/3FeemcwBkJwKMMQC0beu978KEiLrmhPBTFYSIqgKEaKcplV0FUMcwQqi5wFoXx4+TyWI2i+fzZDJZdHo2W02nyyia92K/rwLnnDGmDzvnp9NVGEb39z+Gw4fRKArDaDR6CMNoOByPx5P5PFksktlslWW7oH0J53ya/lwuN0nyGK82m3X6uEk36+1mvU3iY5tnz+k2z/MCYxY0L2GdT5JtFE0fJslgHA/G8ff75WAcfwuP9etoPozWy/Vzmj7vduhV2DoKykj97rO/um3ffmje3LU3H5ubT831XXt911zdtu+/eKs0BfW38GkNeySzQj6VR+alzA9nnZXH0XlVr//cDRhIzi6EEy8t6/f8r5f7ff6H53B/Yd45DhLOl/T7zv48rN4MtNZSSoQQ58IaXRCdVwaBMcZWVY0xkVJpbQgBAK61kVJRyqTUWptf+2RGK5tisD0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1043c2b6b89600a9ad1e47f71f609fa5/e4706/iPad_Wizard_Installation_9020_9010_09.avif 230w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/d1af7/iPad_Wizard_Installation_9020_9010_09.avif 460w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/7f308/iPad_Wizard_Installation_9020_9010_09.avif 920w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/e1c99/iPad_Wizard_Installation_9020_9010_09.avif 1380w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/76ea5/iPad_Wizard_Installation_9020_9010_09.avif 1840w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/b105c/iPad_Wizard_Installation_9020_9010_09.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1043c2b6b89600a9ad1e47f71f609fa5/a0b58/iPad_Wizard_Installation_9020_9010_09.webp 230w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/bc10c/iPad_Wizard_Installation_9020_9010_09.webp 460w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/966d8/iPad_Wizard_Installation_9020_9010_09.webp 920w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/445df/iPad_Wizard_Installation_9020_9010_09.webp 1380w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/78de1/iPad_Wizard_Installation_9020_9010_09.webp 1840w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/03b06/iPad_Wizard_Installation_9020_9010_09.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1043c2b6b89600a9ad1e47f71f609fa5/81c8e/iPad_Wizard_Installation_9020_9010_09.png 230w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/08a84/iPad_Wizard_Installation_9020_9010_09.png 460w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/c0255/iPad_Wizard_Installation_9020_9010_09.png 920w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/b1001/iPad_Wizard_Installation_9020_9010_09.png 1380w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/161ec/iPad_Wizard_Installation_9020_9010_09.png 1840w", "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/49853/iPad_Wizard_Installation_9020_9010_09.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1043c2b6b89600a9ad1e47f71f609fa5/c0255/iPad_Wizard_Installation_9020_9010_09.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/546829b330524964f0fcebbd5b101832/49853/iPad_Wizard_Installation_9020_9010_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABI0lEQVQoz62T3W7DIAyF8/4vuYumFzSlQPizDTZTQhPRXXSbtu/Ckg8+si3hSd3tctfz5aK1BgAf82KzjxnhSSnFGGOtJaKuIKKz9uN6m+zqU0oAkFIqpQJSJqksZYeIQgjdHGMsB1xrQp4eD621bgfCvDrrnBORruAOESFia0OhyOScCyFs2Q4zL8uilCKiusMD9YBrzVgnY4z3vrXGzN2slJrnGQB6eo7Q044wA/Fm1lrLATP3rU4FIMcYYgwpRhlglqnWSkSjeVisVW4RyIXsE4SEVcbHNrVXziE7UNqKzUHze8zl1SyvfOn8nm/MckQZ0n/q/H7nP439I/P5w37XGRFzzlrrGFMhvDm8Gnx4INqOyRiTc97OyDnvfS9e17WLnyI4c6CFckOHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/546829b330524964f0fcebbd5b101832/e4706/iPad_Wizard_Installation_9020_9010_10.avif 230w", "/en/static/546829b330524964f0fcebbd5b101832/d1af7/iPad_Wizard_Installation_9020_9010_10.avif 460w", "/en/static/546829b330524964f0fcebbd5b101832/7f308/iPad_Wizard_Installation_9020_9010_10.avif 920w", "/en/static/546829b330524964f0fcebbd5b101832/e1c99/iPad_Wizard_Installation_9020_9010_10.avif 1380w", "/en/static/546829b330524964f0fcebbd5b101832/76ea5/iPad_Wizard_Installation_9020_9010_10.avif 1840w", "/en/static/546829b330524964f0fcebbd5b101832/b105c/iPad_Wizard_Installation_9020_9010_10.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/546829b330524964f0fcebbd5b101832/a0b58/iPad_Wizard_Installation_9020_9010_10.webp 230w", "/en/static/546829b330524964f0fcebbd5b101832/bc10c/iPad_Wizard_Installation_9020_9010_10.webp 460w", "/en/static/546829b330524964f0fcebbd5b101832/966d8/iPad_Wizard_Installation_9020_9010_10.webp 920w", "/en/static/546829b330524964f0fcebbd5b101832/445df/iPad_Wizard_Installation_9020_9010_10.webp 1380w", "/en/static/546829b330524964f0fcebbd5b101832/78de1/iPad_Wizard_Installation_9020_9010_10.webp 1840w", "/en/static/546829b330524964f0fcebbd5b101832/03b06/iPad_Wizard_Installation_9020_9010_10.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/546829b330524964f0fcebbd5b101832/81c8e/iPad_Wizard_Installation_9020_9010_10.png 230w", "/en/static/546829b330524964f0fcebbd5b101832/08a84/iPad_Wizard_Installation_9020_9010_10.png 460w", "/en/static/546829b330524964f0fcebbd5b101832/c0255/iPad_Wizard_Installation_9020_9010_10.png 920w", "/en/static/546829b330524964f0fcebbd5b101832/b1001/iPad_Wizard_Installation_9020_9010_10.png 1380w", "/en/static/546829b330524964f0fcebbd5b101832/161ec/iPad_Wizard_Installation_9020_9010_10.png 1840w", "/en/static/546829b330524964f0fcebbd5b101832/49853/iPad_Wizard_Installation_9020_9010_10.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/546829b330524964f0fcebbd5b101832/c0255/iPad_Wizard_Installation_9020_9010_10.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a7c687c7382c21d6453da3e90b75a1be/49853/iPad_Wizard_Installation_9020_9010_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABX0lEQVQoz5WSSU5kMQyG3x1ZtoTElZD6CCwQcA5Y0QIOgFAxQypjxVMSo/dSVUwFNJ+ysCz/v53Ew8Oze3o217OZMQYR4wKePIYECCM5ZyKy1jrniCjnDACI6Jy9vHkcXIg5Z0TMOYsUJE4gxCIipdbWmoiEELz3pZTWWq2VRWopC6rDfG6MMbqitbaOc85hYj7hvQ8hIOK6YPDep5S6rBtba3tGRIhIVjAzEZVSXsXW2hijqtZpyFKKc46Z9Vt68Sg2xrQVtVbnXLf7kaGUwsxvxR9m+67zh2xrDSdEZCxQ/XxUlVmYZWjvqbUCQEqJIGmVrzr3Fdgg7p818/rvVs/uxnNxr+d3y/j0Vq/c6s5fiXePdWtPtw/0z77uHOnO4RhsH4zJvyfLhdhwZ/1vNnReuoxGuvHB1v5L8XrDfteZiADAGLNYZGF69HRjaR6RWewEABCR9z7G2ItDCD35ApohaGaLEp/QAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7c687c7382c21d6453da3e90b75a1be/e4706/iPad_Wizard_Installation_9020_9010_11.avif 230w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/d1af7/iPad_Wizard_Installation_9020_9010_11.avif 460w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/7f308/iPad_Wizard_Installation_9020_9010_11.avif 920w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/e1c99/iPad_Wizard_Installation_9020_9010_11.avif 1380w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/76ea5/iPad_Wizard_Installation_9020_9010_11.avif 1840w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/b105c/iPad_Wizard_Installation_9020_9010_11.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a7c687c7382c21d6453da3e90b75a1be/a0b58/iPad_Wizard_Installation_9020_9010_11.webp 230w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/bc10c/iPad_Wizard_Installation_9020_9010_11.webp 460w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/966d8/iPad_Wizard_Installation_9020_9010_11.webp 920w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/445df/iPad_Wizard_Installation_9020_9010_11.webp 1380w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/78de1/iPad_Wizard_Installation_9020_9010_11.webp 1840w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/03b06/iPad_Wizard_Installation_9020_9010_11.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7c687c7382c21d6453da3e90b75a1be/81c8e/iPad_Wizard_Installation_9020_9010_11.png 230w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/08a84/iPad_Wizard_Installation_9020_9010_11.png 460w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/c0255/iPad_Wizard_Installation_9020_9010_11.png 920w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/b1001/iPad_Wizard_Installation_9020_9010_11.png 1380w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/161ec/iPad_Wizard_Installation_9020_9010_11.png 1840w", "/en/static/a7c687c7382c21d6453da3e90b75a1be/49853/iPad_Wizard_Installation_9020_9010_11.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a7c687c7382c21d6453da3e90b75a1be/c0255/iPad_Wizard_Installation_9020_9010_11.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[12]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b6e182458aaa1939d85dee58ad539a48/49853/iPad_Wizard_Installation_9020_9010_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz7WT3UrDMBSA+/J6I2PeTB9Eb8QLBTfY3mCg4MXchV3T9DRtz0+THGm7DkTFDfEjHJKTfEkOJMl7Vuwy87bZGGOIyNW4A3Q1Eu5hZmttURQiMmSIqCjs6zZNitI1TUNETdO0rSeWCkVaH3u894joeogohBBj7GIINYckz40xRkdiCKrKzIMAAFmW5SNDBgBERFUTAKiqqtN6QggAkKapq2qktkFGbpFbki4iSY2MxN77TrbWOudU9XClPM+zbBdj0J+JMe5lY0wc6bfopm/Wevagk7lePOnlQqd9ZzLX80e9XQ++Jt57Efkq3z3rdKFXK50t9XrVtdmyG04Xev8yyt/e50iS+JkQ9qWGqP6HFuJv8p9OPkr+l5pPkA8v7LSTmRkRjTF13bTCKfDWcu5IpLU9w8cCAOfcsLgsyyH5Aec1Z69ItGC3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b6e182458aaa1939d85dee58ad539a48/e4706/iPad_Wizard_Installation_9020_9010_12.avif 230w", "/en/static/b6e182458aaa1939d85dee58ad539a48/d1af7/iPad_Wizard_Installation_9020_9010_12.avif 460w", "/en/static/b6e182458aaa1939d85dee58ad539a48/7f308/iPad_Wizard_Installation_9020_9010_12.avif 920w", "/en/static/b6e182458aaa1939d85dee58ad539a48/e1c99/iPad_Wizard_Installation_9020_9010_12.avif 1380w", "/en/static/b6e182458aaa1939d85dee58ad539a48/76ea5/iPad_Wizard_Installation_9020_9010_12.avif 1840w", "/en/static/b6e182458aaa1939d85dee58ad539a48/b105c/iPad_Wizard_Installation_9020_9010_12.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b6e182458aaa1939d85dee58ad539a48/a0b58/iPad_Wizard_Installation_9020_9010_12.webp 230w", "/en/static/b6e182458aaa1939d85dee58ad539a48/bc10c/iPad_Wizard_Installation_9020_9010_12.webp 460w", "/en/static/b6e182458aaa1939d85dee58ad539a48/966d8/iPad_Wizard_Installation_9020_9010_12.webp 920w", "/en/static/b6e182458aaa1939d85dee58ad539a48/445df/iPad_Wizard_Installation_9020_9010_12.webp 1380w", "/en/static/b6e182458aaa1939d85dee58ad539a48/78de1/iPad_Wizard_Installation_9020_9010_12.webp 1840w", "/en/static/b6e182458aaa1939d85dee58ad539a48/03b06/iPad_Wizard_Installation_9020_9010_12.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b6e182458aaa1939d85dee58ad539a48/81c8e/iPad_Wizard_Installation_9020_9010_12.png 230w", "/en/static/b6e182458aaa1939d85dee58ad539a48/08a84/iPad_Wizard_Installation_9020_9010_12.png 460w", "/en/static/b6e182458aaa1939d85dee58ad539a48/c0255/iPad_Wizard_Installation_9020_9010_12.png 920w", "/en/static/b6e182458aaa1939d85dee58ad539a48/b1001/iPad_Wizard_Installation_9020_9010_12.png 1380w", "/en/static/b6e182458aaa1939d85dee58ad539a48/161ec/iPad_Wizard_Installation_9020_9010_12.png 1840w", "/en/static/b6e182458aaa1939d85dee58ad539a48/49853/iPad_Wizard_Installation_9020_9010_12.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b6e182458aaa1939d85dee58ad539a48/c0255/iPad_Wizard_Installation_9020_9010_12.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[13]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/49853/iPad_Wizard_Installation_9020_9010_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeUlEQVQoz6WSS2ocMRCGdVmvDIbcxuQKhsE4Z3A23hrv/RhId6b0qpJKKpVaoT2eJiaYGPzzIUpSfRICmengfx/g5fkZAEoplIsl5araumpXXXofiIko9T5Ul9Z6ax2Rnn5Z4yPmnEspOeemykU8CiVJqXiPAMG5OE0wz+AcWhtCoJylVvWkxloAgHFK7927VRDRlDhGIspETJQRE2JirjHSPAMimfUoojHGcgrAYZ7nZRkr441jlmUtQ4j7/QsRGeccIv4tWwuIcev/h9G7TtPEzKsMAJupqtb64P3dk+7u+4+HfvOwjluxu+93j80CEGWjqiKyya1piJyJL2/r2VW7uG7nu/btZuV8t07Prtr3n1JS8YHNeB/VHiPHyMo86onyymmqzMces7xPa3rc8IHdB/jwP/kzfE3+6M1fvTmE/Cl5+yRdNSHj6x5iQSxbK+JWvC2aWiszA0BKuUmdQ91bARSRZq13LjCXWiUEREy1CnOJkZhrrfIHRGdYz//6aVIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/e4706/iPad_Wizard_Installation_9020_9010_13.avif 230w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/d1af7/iPad_Wizard_Installation_9020_9010_13.avif 460w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/7f308/iPad_Wizard_Installation_9020_9010_13.avif 920w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/e1c99/iPad_Wizard_Installation_9020_9010_13.avif 1380w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/76ea5/iPad_Wizard_Installation_9020_9010_13.avif 1840w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/b105c/iPad_Wizard_Installation_9020_9010_13.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/a0b58/iPad_Wizard_Installation_9020_9010_13.webp 230w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/bc10c/iPad_Wizard_Installation_9020_9010_13.webp 460w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/966d8/iPad_Wizard_Installation_9020_9010_13.webp 920w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/445df/iPad_Wizard_Installation_9020_9010_13.webp 1380w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/78de1/iPad_Wizard_Installation_9020_9010_13.webp 1840w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/03b06/iPad_Wizard_Installation_9020_9010_13.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/81c8e/iPad_Wizard_Installation_9020_9010_13.png 230w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/08a84/iPad_Wizard_Installation_9020_9010_13.png 460w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/c0255/iPad_Wizard_Installation_9020_9010_13.png 920w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/b1001/iPad_Wizard_Installation_9020_9010_13.png 1380w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/161ec/iPad_Wizard_Installation_9020_9010_13.png 1840w", "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/49853/iPad_Wizard_Installation_9020_9010_13.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7b2a2d8c8749c5c9cbf44453c2e00d03/c0255/iPad_Wizard_Installation_9020_9010_13.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[14]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      